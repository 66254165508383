<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div style="direction: ltr;" class="col-sm-12 text-center">© {{ year }} RushRooms. All rights reserved .</div>
      
      <!-- <div class="col-sm-6">
         // removed by client :( request
        <div style="display: none" class="text-sm-end d-none d-sm-block">
          Design & Develop by Digital Creativity
        </div>
       
      </div> -->
    </div>
  </div>
</footer>
