import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutsService {
  public UnreadedMessagesSub$ = new BehaviorSubject<number>(0);

  constructor() {}
}
